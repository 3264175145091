// removePinnedNotification.js
'use strict';
import { REMOVE_OPERATION_DATA } from '../ActionTypes.js';

/**
 * Remove pinned notification
 * @kind action
 * @param {string} {componentName} - the notification component that needs to be dropped.
 * @return {Promise} Action promise.
 */
const removePinnedNotification =
  ({ componentName }) =>
  async dispatch => {
    return dispatch({
      type: REMOVE_OPERATION_DATA,
      payload: {
        selectPath: ['pinnedNotification', componentName],
      },
    });
  };

export default removePinnedNotification;
