// openBindEmailModal.js
'use strict';
import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';
import getEmailCooldownTimestamp from '../selector/getEmailCooldownTimestamp.js';
import updateRegisterData from '../action/updateRegisterData.js';
import addModal from '../action/addModal.js';
import { register as registerModal } from '../resource/modalId.js';
import { MERGE_OPERATION_DATA, SET_OPERATION_DATA } from '../ActionTypes.js';

/**
 * Open bind email modal
 * @kind action
 * @param {boolean} {[canClose = false]} - can close register email modal or not.
 * @param {string} {entry} - bind email modal entry.
 * @return {Promise} Action promise.
 */
const openBindEmailModal =
  ({ canClose = false, entry } = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['emailBindingModal'],
        data: {
          canClose,
        },
      },
    });
    dispatch({
      type: SET_OPERATION_DATA,
      payload: {
        selectPath: ['bindEmail', 'bind-entry'],
        data: entry,
      },
    });
    const email = getMeData(getState(), 'accountLinks')?.['email-unverified']
      ?.id;
    const sentCount =
      getOperationData(
        getState(),
        ['register', 'emailOtp', email],
        'sentCount'
      ) || 0;
    const emailCooldownTimestamp = getEmailCooldownTimestamp(
      getState(),
      ['register', 'emailOtp'],
      email
    );
    if (sentCount && Date.now() < emailCooldownTimestamp) {
      dispatch(updateRegisterData({ dataKey: 'email', value: email }));
      return dispatch(
        addModal({
          id: registerModal.EMAIL_VERIFY,
          transitionStatus: 'coming',
          isHigherThanAll: true,
        })
      );
    }
    return dispatch(
      addModal({
        id: registerModal.REGISTER_EMAIL,
        transitionStatus: 'coming',
        isHigherThanAll: true,
      })
    );
  };

export default openBindEmailModal;
