// authEntryType.js
'use strict';

/**
 * Entry type
 */

export const BindEmail = {
  ACCOUNT_LINKS: 'accountLinks',
  BINDING_ALERT: 'bindingAlert',
  PASSWORD_SETTING: 'passwordSetting',
  REGISTER: 'register',
  JOIN: 'join',
};

export const PasswordLoginEntry = {
  LOGIN: 'login',
  REGISTER: 'register',
  PASSWORD_SETTING: 'passwordSetting',
  JOIN: 'join',
};

export const EmailTemplate = {
  SIGNUP: 'email-signup-verification',
  LOGIN: 'email-login-verification',
  BINDING: 'email-binding-verification',
  FORGOT_PASSWORD: 'email-forget-password-verification',
};
