// addPinnedNotification.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';

/**
 * Add pinned notification
 * @kind action
 * @param {string} {componentName} - the notification component that needs to be displayed.
 * @return {Promise} Action promise.
 */
const addPinnedNotification =
  ({ componentName }) =>
  async dispatch => {
    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['pinnedNotification'],
        data: {
          [componentName]: true,
        },
      },
    });
  };

export default addPinnedNotification;
