// openBindPhoneModal.js
'use strict';
import addModal from '../action/addModal.js';
import updateRegisterData from '../action/updateRegisterData.js';
import {
  login as loginModal,
  register as registerModal,
} from '../resource/modalId.js';

/**
 * Open bind phone modal
 * @kind action
 * @param {boolean} {isFromAccountLink} - is open modal from account link button.
 * @return {Promise} Action promise.
 */
const openBindPhoneModal =
  ({ isFromAccountLink = false } = {}) =>
  async dispatch => {
    dispatch(
      updateRegisterData({
        dataKey: 'isBindingPhoneFromAccountLink',
        value: Boolean(isFromAccountLink),
      })
    );
    dispatch(updateRegisterData({ dataKey: 'isBindingPhone', value: true }));
    return dispatch(
      addModal({
        id: loginModal.PHONE,
        transitionStatus: 'coming',
        higherThanIds: [registerModal.REGISTER_EMAIL],
      })
    );
  };

export default openBindPhoneModal;
